<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- <b-link class="brand-logo">
    </b-link> -->
    <!-- <b-image :src="VuexyLogo"></b-image> -->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Upgrade Your Plan</h2>
        <p class="mb-2">This feature is not available in your current subscription plan. Please upgrade your subscription to access this feature.</p>
        <b-button variant="primary" class="mb-2 btn-sm-block mr-1" @click="goBack">
         Back To Home </b-button
        >
        <b-button variant="primary" class="mb-2 btn-sm-block" @click="goToSecusyContact">
          Explore Plans </b-button
        >
        <br />

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
      errorMessage:this.$route.query.errorMessage,
     
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    goBack() {
     this.$router.push("/")
    },
    goToSecusyContact(){
      const secusy_link ='https://secusy.ai/contact/';
      window.open(secusy_link, '_blank');
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
